/*
 * Tic Tac Toe - Minimalistic Tic Tac Toe
 * Copyright (C) 2021  Vidhu Kant Sharma
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

body {
  margin: 0;
  padding: 0;
  background-color: #232627;
  color: white;
}

#root {
  position: absolute;
  margin: auto;
  top: 0; bottom: 0;
  left: 0; right: 0;
}

.GamemodeChooser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 6rem);
  width: 100vw;
}

.GamemodeChooser .GamemodeButton {
  border: 1px solid #5b76b7;
  text-align: center;
  font-size: 1.5rem;
  padding: 0.5rem 0;
  width: 15rem;
  margin: 1rem;
}

.MessageBox {
  font-size: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  position: fixed;
  top: 0; bottom: 0;
  left: 0; right: 0;
  z-index: 10;
}

.MessageBox p {
  background-color: #000000dd;
  padding: 0.5rem 2rem;
}

.ScoreBoardContainer {
  text-align: center;
  position: relative;
  left: 0; right: 0;
  margin: 1rem auto;
  width: 30rem;
  min-height: 9rem;
}

.ScoreBoard { 
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-size: 3rem;
}

.Grid {
  height: calc(100vh - 17rem);
  display: grid;
  grid-template-columns: 9rem 9rem 9rem;
  grid-template-rows: 9rem 9rem 9rem;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;

  position: relative;
  left: 0; right: 0;
  margin: 1rem auto;
  width: 30rem;
}

.Box {
  border: 1px solid #5b76b7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 7rem;
}

.Footer {
  min-height: 6rem;
  position: relative;
  bottom: 0;
  width: 100%;
  padding: 0.2rem 0;
  background-color: #0f0f0f;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.Footer p {
  color: #a75cb8;
  margin: 0.2rem;
  width: 90%;
}

.Footer a {
  color: white;
}

@media only screen and (max-width: 600px) {
  .MessageBox {
    font-size: 4rem;
  }
  .ScoreBoardContainer {
    width: 20rem;
  }
  .ScoreBoard { 
    font-size: 3.5rem;
  }
  .Grid {
    grid-template-columns: 6rem 6rem 6rem;
    grid-template-rows: 6rem 6rem 6rem;
    grid-row-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    width: 21rem;
  }
  .Box {
    font-size: 6rem;
  }
}
